
$(document).ready(function () {

    var num = 36; // the total number of images

    //// Preload all the images into hidden div
    //if ( $(".content-wrapper-threesixty-new").length && imageFolder != undefined ) {
    //    for (var i = 1; i <= num; i++) {
    //        var imgNumString = i + "";
    //        if (i < 10) { imgNumString = "0" + i; }
    //        var img = document.createElement('img');
    //        img.className = 'lazy-loading';
    //        img.dataset.src = imageFolder + '/' + imgNumString + "_" + imageFileNameBase + '.jpg';
    //        // append
    //        document.getElementById('preload-imgs').appendChild(img);
    //    }
    //    $.force_appear();
    //}

    // Control swipes using jquery.touchSwipe.min.js
    // http://labs.rampinteractive.co.uk/touchSwipe/
    var swipeOptions =
    {
        triggerOnTouchEnd: true,
        swipeStatus: swipeStatus,
        allowPageScroll: "vertical",
        threshold: 75
    }

    $(function () {
        if ($(".content-wrapper-threesixty-new").length)
        {
            imgs = $(".img-container"); // the element that will be swipeable
            imgs.swipe(swipeOptions);
            // start
            changeImg(40);
        }
    });

    function swipeStatus(event, phase, direction, distance) {
        var duration = 0;
        if (direction == "left") {
            changeImg(distance);
        }
        else if (direction == "right") {
            changeImgR(-distance);
        }
    }

    function changeImg(imgNum) {

        // spread it out so it doesn't load new img every single px of swipe distance
        imgNum = Math.floor(imgNum / 8);

        if (imgNum < 1) {
            imgNum += num;
        }
        if (imgNum > num) {
            imgNum -= num;
        }
        // leading zero
        var imgNumString = imgNum + "";
        if (imgNum < 10) { imgNumString = "0" + imgNum; }

        // change the image src
        document.getElementById("myImg").src = imageFolder + "/" + imgNumString + "_" + imageFileNameBase + ".jpg";

    }

    function changeImgR(imgNum) {

        // spread it out so it doesn't load new img every single px of swipe distance
        imgNum = Math.floor(imgNum / 8);

        var num2 = -Math.abs(num);
        if (imgNum > num2) {
            imgNum += num;
        }
        if (imgNum <= num2) {
            imgNum += num * 2;
        }
        // leading zero
        var imgNumString = imgNum + "";
        if (imgNum < 10) { imgNumString = "0" + imgNum; }

        // change the image src
        document.getElementById("myImg").src = imageFolder + "/" + imgNumString + "_" + imageFileNameBase + ".jpg";

    }

});